/*
 * Note: This file should only be used for classes whose colour can be changed by users
 * via the system setting screen.
*/
body.login {
  background-color: #387bbd;
}
#application > header {
  background-color: #387bbd;
}
#menu a,
#menu button {
  color: rgba(255, 255, 255, 0.75);
}
#menu a:hover,
#menu button:hover {
  background-color: #6a9cce;
  color: white;
}
#menu a:focus,
#menu button:focus,
#menu a[aria-expanded="true"],
#menu button[aria-expanded="true"] {
  background-color: #2a5c8e;
  color: white;
}
html footer#menu-mobile {
  background-color: #2a5c8e;
}
@media only screen and (min-width: 481px) {
  html footer#menu-mobile [role="menu"][aria-expanded="true"] {
    background-color: #2a5c8e;
  }
}
html footer#menu-mobile [role="menu"] .dropdown-divider {
  border-top-color: rgba(255, 255, 255, 0.25);
}
html footer#menu-mobile [role="menu"] a,
html footer#menu-mobile [role="menu"] button {
  color: rgba(255, 255, 255, 0.75);
}
html footer#menu-mobile [role="menu"] a:hover,
html footer#menu-mobile [role="menu"] button:hover,
html footer#menu-mobile [role="menu"] a:focus,
html footer#menu-mobile [role="menu"] button:focus {
  background-color: #6a9cce;
  color: white;
}
#load.ess-progress .ess-progress-bar {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.33), rgba(255, 255, 255, 0.33));
}
#logo {
  background-image: url("../images/logo.png");
}
@keyframes dtb-spinner {
  100% {
    transform: rotate(360deg);
  }
}
@-o-keyframes dtb-spinner {
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-ms-keyframes dtb-spinner {
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dtb-spinner {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes dtb-spinner {
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
div.dt-button-info {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  margin-top: -100px;
  margin-left: -200px;
  background-color: white;
  border: 2px solid #111;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  text-align: center;
  z-index: 21;
}
div.dt-button-info h2 {
  padding: 0.5em;
  margin: 0;
  font-weight: normal;
  border-bottom: 1px solid #ddd;
  background-color: #f3f3f3;
}
div.dt-button-info > div {
  padding: 1em;
}

ul.dt-button-collection.dropdown-menu {
  display: block;
  z-index: 2002;
  -webkit-column-gap: 8px;
  -moz-column-gap: 8px;
  -ms-column-gap: 8px;
  -o-column-gap: 8px;
  column-gap: 8px;
}
ul.dt-button-collection.dropdown-menu.fixed {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -75px;
  border-radius: 0;
}
ul.dt-button-collection.dropdown-menu.fixed.two-column {
  margin-left: -150px;
}
ul.dt-button-collection.dropdown-menu.fixed.three-column {
  margin-left: -225px;
}
ul.dt-button-collection.dropdown-menu.fixed.four-column {
  margin-left: -300px;
}
ul.dt-button-collection.dropdown-menu > * {
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
}
ul.dt-button-collection.dropdown-menu.two-column {
  width: 300px;
  padding-bottom: 1px;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  -ms-column-count: 2;
  -o-column-count: 2;
  column-count: 2;
}
ul.dt-button-collection.dropdown-menu.three-column {
  width: 450px;
  padding-bottom: 1px;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  -ms-column-count: 3;
  -o-column-count: 3;
  column-count: 3;
}
ul.dt-button-collection.dropdown-menu.four-column {
  width: 600px;
  padding-bottom: 1px;
  -webkit-column-count: 4;
  -moz-column-count: 4;
  -ms-column-count: 4;
  -o-column-count: 4;
  column-count: 4;
}
ul.dt-button-collection.dropdown-menu .dt-button {
  border-radius: 0;
}

ul.dt-button-collection {
  -webkit-column-gap: 8px;
  -moz-column-gap: 8px;
  -ms-column-gap: 8px;
  -o-column-gap: 8px;
  column-gap: 8px;
}
ul.dt-button-collection.fixed {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -75px;
  border-radius: 0;
}
ul.dt-button-collection.fixed.two-column {
  margin-left: -150px;
}
ul.dt-button-collection.fixed.three-column {
  margin-left: -225px;
}
ul.dt-button-collection.fixed.four-column {
  margin-left: -300px;
}
ul.dt-button-collection > * {
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
}
ul.dt-button-collection.two-column {
  width: 300px;
  padding-bottom: 1px;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  -ms-column-count: 2;
  -o-column-count: 2;
  column-count: 2;
}
ul.dt-button-collection.three-column {
  width: 450px;
  padding-bottom: 1px;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  -ms-column-count: 3;
  -o-column-count: 3;
  column-count: 3;
}
ul.dt-button-collection.four-column {
  width: 600px;
  padding-bottom: 1px;
  -webkit-column-count: 4;
  -moz-column-count: 4;
  -ms-column-count: 4;
  -o-column-count: 4;
  column-count: 4;
}
ul.dt-button-collection .dt-button {
  border-radius: 0;
}
ul.dt-button-collection.fixed {
  max-width: none;
}
ul.dt-button-collection.fixed:before, ul.dt-button-collection.fixed:after {
  display: none;
}

div.dt-button-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

@media screen and (max-width: 767px) {
  div.dt-buttons {
    float: none;
    width: 100%;
  }
  div.dt-buttons a.btn {
    float: none;
  }
}
div.dt-buttons button.btn.processing,
div.dt-buttons div.btn.processing,
div.dt-buttons a.btn.processing {
  color: rgba(0, 0, 0, 0.2);
}
div.dt-buttons button.btn.processing:after,
div.dt-buttons div.btn.processing:after,
div.dt-buttons a.btn.processing:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  margin: -8px 0 0 -8px;
  box-sizing: border-box;
  display: block;
  content: ' ';
  border: 2px solid #282828;
  border-radius: 50%;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: dtb-spinner 1500ms infinite linear;
  -o-animation: dtb-spinner 1500ms infinite linear;
  -ms-animation: dtb-spinner 1500ms infinite linear;
  -webkit-animation: dtb-spinner 1500ms infinite linear;
  -moz-animation: dtb-spinner 1500ms infinite linear;
}

#bpa-reports #bpa-report-xml-result-list header {
  display: block;
}
.alert-link {
  cursor: pointer;
}
.dataTables_button,
.buttons-collection {
  margin-bottom: 0.5rem;
}
.dataTables_processing {
  z-index: 2;
}
.dataTables_full-screen {
  border-radius: 0;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2500;
}
.dt-search,
.dt-info,
.dt-length,
.dt-paging {
  margin: 0;
}
@media screen and (max-width: 767px) {
  div.dataTables_wrapper div.dt-paging ul.pagination {
    justify-content: center;
  }
}
.modal,
.modal-header,
.modal-open {
  margin-right: 0 !important;
  padding-right: 0 !important;
}
@media print {
  .staff-schedule-print button,
  .staff-schedule-print section {
    display: none;
  }
}
table td.date:not(td:first-child),
table td.number,
table td.time {
  text-align: right;
}
.table > :not(:last-child) > :last-child > * {
  border-bottom-color: inherit;
}
.table-clickable-row.selected td {
  background-color: #2a5c8e;
  color: #fff;
}
.table-clickable-row.selected td:first-child:before {
  box-sizing: content-box;
  content: none;
  display: inline-block;
  font-family: iconic-sm, monospace;
  font-size: 0.75rem;
  line-height: 1;
  margin-left: -1.25rem;
  margin-right: 0.5rem;
  speak: none;
  text-align: center;
  vertical-align: baseline;
}
td.required.empty-cell::after {
  color: #bd2c00;
  content: "*";
  font-size: 1rem;
  font-weight: 700;
  line-height: 1px;
}
.text-label {
  color: #959a9f;
}
label:not(.btn).text-hide {
  color: transparent !important;
  background-color: transparent;
}
.flatpickr-calendar .numInputWrapper span {
  right: auto;
}
.dropdown-item.text-facebook {
  color: #3b5998;
}
.dropdown-item.text-linkedin {
  color: #007bb6;
}
.dropdown-item.text-twitter {
  color: #00aced;
}
.timekeeper-custom-html {
  line-height: 1;
}
.seek-apply-btn-iframe {
  height: 42px;
  width: 205px;
  min-width: 205px;
  margin-right: 0.125rem;
  margin-left: 0.125rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .seek-apply-btn-iframe {
    margin-top: 0.25em;
  }
  a.next-seek-iframe {
    margin-top: 0.25em;
    width: 100%;
  }
}
@media only screen and (max-width: 575.98px) {
  .seek-apply-btn-iframe {
    width: 100%;
    height: 38px;
    margin: 0.125rem 0;
  }
}
.radio-label {
  color: #181a1b !important;
  display: block;
  text-transform: none !important;
}
.radio-label input[type="radio"] {
  vertical-align: middle;
  margin-top: -4px;
}
.radiogroup > div {
  padding-top: calc(0.375rem + 1px);
}
#autoSaveNotification {
  z-index: 2600;
  visibility: hidden;
  opacity: 0;
  transition: opacity 500ms;
}
#autoSaveNotification.visible {
  opacity: 1;
  visibility: visible;
}
#autoSaveNotification.hiding {
  visibility: visible;
}
.notification-below-menu {
  top: 60px;
}
.text-pre-wrap {
  white-space: pre-wrap;
}
.btn-no-border {
  border: 0;
}
.scale150 {
  transform: scale(1.5);
}
.cursor-default {
  cursor: default;
}
.btn.btn-outline-danger.disabled,
.btn.btn-outline-danger:disabled {
  opacity: 0.35;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-select-action {
  font-weight: bold;
  color: #387bbd;
}
.flex-flow-row-wrap {
  flex-flow: row wrap;
}
.px-10px {
  padding-left: 10px;
  padding-right: 10px;
}
.px-15px {
  padding-left: 15px;
  padding-right: 15px;
}
.ps-0 {
  padding-left: 0;
}
.checkbox-padding {
  padding-left: 1.5rem;
  padding-right: 1rem;
}
.me-8px {
  margin-right: 8px;
}
.height-100pct {
  height: 100%;
}
@media only screen and (min-width: 768px) {
  .ms-lg-8px {
    margin-left: 8px !important;
  }
}
.mt-lg-8px {
  margin-top: 8px !important;
}
@media only screen and (min-width: 768px) {
  .mt-lg-8px {
    margin-top: 0px !important;
  }
}
.toggle-flex {
  flex-direction: column !important;
}
@media only screen and (min-width: 768px) {
  .toggle-flex {
    flex-direction: row !important;
  }
}
.ms-button-group {
  margin-left: 3.5rem;
}
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #387bbd;
  border-color: #387bbd;
}
.action-items .actionable-list [role="group"]:hover,
.section-accordian .actionable-list [role="group"]:hover {
  background: transparent;
}
@media only screen and (min-width: 768px) {
  .action-items .actionable-list [role="group"]:hover,
  .section-accordian .actionable-list [role="group"]:hover {
    background: #cddeef;
  }
}
.flex-basis-auto {
  flex-basis: auto;
}
.font-size-inherit {
  font-size: inherit;
}
.modal-content-erm-bulk-process {
  margin-top: 20px;
}
.container-full-screen {
  max-width: 90%;
}
.erm-flag {
  color: red;
}
.send-message-form {
  padding: 1em 1em 0 1em;
}
.send-message-form__actions button {
  margin: 0.125em;
}
.send-message-form__field {
  padding-top: 0.5em;
  text-align: left;
}
.send-message-form__field.radio-group {
  display: flex;
  justify-content: space-evenly;
}
.send-message-form__field.radio-group label {
  display: inline-flex;
  align-items: center;
  gap: 7px;
}
.send-message-form__keywords-button {
  background: none !important;
  border: none;
  padding: 0 !important;
  margin: 0.5em 0;
  text-decoration: underline;
  cursor: pointer;
}
.send-message-form__keywords-button:focus {
  outline: none;
}
.send-message-form__keywords-list {
  text-align: left;
  font-size: smaller;
}
.send-message-results__heading {
  padding-top: 1em;
  padding-bottom: 0.5em;
}
.horizontal-scroll {
  overflow-x: auto;
  white-space: nowrap;
}
.progress-step-horizontal {
  display: inline-block;
  max-width: 95px;
  width: 95px;
  vertical-align: top;
}
.rounded-start {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.floating-button-bar-sticky {
  margin-top: 1.5rem;
}
.is-floating {
  z-index: 101 !important;
}
@media only screen and (min-width: 768px) {
  .floating-button-bar {
    padding: 1.25rem 1.25rem 0.25rem 1.25rem;
    border-radius: 0.25rem;
    margin-top: 1rem;
    box-shadow: none;
    background-color: #fff;
  }
  .floating-button-bar-margin {
    margin: 0 -20px -10px -20px;
  }
  .floating-button-bar-sticky {
    position: relative;
    position: -webkit-sticky;
    position: sticky;
    width: auto;
    z-index: 99;
    bottom: -1px;
  }
  .floating-button-bar-sticky.inside-modal {
    margin: -13px;
    margin-bottom: -28px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: none;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    padding-top: 0px;
  }
  .inside-modal .floating-button-bar {
    padding: 0.25rem 1.75rem 1.25rem 2.65rem;
  }
  .is-floating .floating-button-bar {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    box-shadow: 0 0.125rem 0 rgba(0, 0, 0, 0.075), 0 0 0.5rem -0.25rem rgba(0, 0, 0, 0.5);
  }
  #timekeeper .action-items:not(.d-none),
  .timekeeper .action-items:not(.d-none) {
    transform: translateX(-44px);
  }
}
@media only screen and (min-width: 481px) {
  .new-alert {
    width: 100%;
  }
}
.max-height-38 {
  max-height: 38px;
}
#user .pic.desktop {
  margin: 0px 12px 0 0;
}
.custom-switch-top:after {
  margin-top: 0.65rem;
}
.colour-selector {
  border-left-width: 2.5rem;
}
.svg {
  fill: #387BBD;
}
.svg .svg-clip-path-1 {
  clip-path: url(#SVGID_00000039847035408328987370000014321118689431679106_);
}
.svg .svg-clip-path-2 {
  clip-path: url(#SVGID_00000050633536861890054800000017723051020945200783_);
}
.svg .svg-clip-path-3 {
  clip-path: url(#SVGID_00000050662106518276189940000010851093767453296042_);
}
.survey-exp .comments-field {
  margin-right: 1.5rem;
}
@media only screen and (min-width: 768px) {
  .survey-input-max-height {
    max-height: 2.625rem;
  }
  .survey-exp .comments-field {
    margin-right: 1.5rem;
    margin-left: -0.5rem;
  }
}
.survey-exp .table-responsive {
  padding-right: inherit;
  padding-left: inherit;
}
.survey-exp .col-form-label,
.survey-exp .table-radio-label {
  color: black !important;
  font-size: 0.938rem !important;
}
div.select div.header-select {
  display: flex;
  padding: 0;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  border-width: 1px;
  border-style: inherit;
  border-color: inherit;
  border-radius: inherit;
  min-height: 2.625rem;
}
div.select:focus {
  border-color: #9cbdde;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(56, 123, 189, 0.25);
}
div.select button {
  margin: 0;
  top: 0;
  right: 0;
  background-color: #7a8187;
  color: #fff;
  border-radius: 0 0 0 0;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border: 1px solid #7a8187;
  border-left-width: 1px;
  border-left-width: 0;
  font-size: 1.125rem;
  padding: 0;
  width: 42px;
}
div.select:not([multiple]) .header-select span {
  flex: 1;
  padding: 0.5em;
  height: 2.625rem;
}
div.select button:hover {
  background-color: #62676c;
}
.select .header-select select {
  appearance: none;
  display: none;
}
.select .header-select select optgroup {
  display: none;
}
.select select div.option {
  display: none;
}
div.select {
  user-select: none;
  box-sizing: border-box;
  position: relative;
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 0;
  border-color: gray;
  width: 100%;
  display: flex;
}
div.select datalist {
  appearance: none;
  position: absolute;
  border-style: solid;
  border-width: 1px;
  border-color: gray;
  left: 0;
  display: none;
  width: 100%;
  max-height: 250px;
  box-sizing: border-box;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow-y: scroll;
  z-index: 100;
  top: 42px;
  overscroll-behavior: none;
}
div.select datalist div.option {
  background-color: white;
  cursor: pointer;
  padding: 0.5em;
  border-width: 0;
}
div.select datalist .opt-group-label {
  background: white;
  padding: 0.5rem;
}
div.select[data-open] {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
div.select[data-open] datalist {
  display: initial;
}
div.select datalist div.option:hover,
div.select datalist .option-selected:hover,
div.select datalist div.option:focus,
div.select datalist div.option:checked {
  background-color: #387bbd;
  color: white;
}
div.select datalist div.option:hover .glyphicons-ok,
div.select datalist .option-selected:hover .glyphicons-ok,
div.select datalist div.option:focus .glyphicons-ok,
div.select datalist div.option:checked .glyphicons-ok {
  color: white;
}
div.select div.optgroup div.option[data-disabled] {
  color: gray;
}
div.select div.optgroup div.option[data-checked] {
  background-color: #387bbd;
  color: white;
}
div.select div.optgroup div.label {
  font-weight: bold;
}
div.select div.optgroup div.option div.label {
  font-weight: normal;
  padding: 0.25em;
}
datalist .label {
  min-height: 24px;
}
.combobox .form-input-wrap .select {
  min-height: 42px;
}
div.select div.option[aria-selected="true"] {
  background-color: white;
}
div.select div.option .glyphicons-ok {
  color: #387bbd;
  padding-right: 1rem;
}
div.select div.option-current {
  border-width: unset !important;
  border: 2px #387bbd solid;
  overflow: initial !important;
  scroll-margin-top: 60px;
}
div.select .header-select.form-control-danger {
  padding-left: 0.5625rem !important;
  border-left-color: #bd2c00 !important;
  border-left-width: 0.25rem !important;
}
div.select[disabled] {
  background-color: #e4e6e7;
  opacity: 1;
}
div.select[disabled] button {
  background-color: #c3c7c9;
  border-width: 0px;
  border-left: 1px;
  border-color: inherit;
  border-style: solid;
  cursor: unset;
}
div[multiple] .header-select {
  height: auto;
  padding: 0.5em;
}
div[multiple] .option-pills {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  padding: 0.125rem;
}
div[multiple] .option-pills .empty-span {
  padding: 0rem;
  height: auto;
}
div[multiple] .opt-span {
  display: flex;
  border: 1px #959a9f solid;
  background-color: #e4e6e7;
  border-radius: 0.25rem;
  min-height: 2rem;
  height: auto;
  padding: 0.1rem 0rem 0rem 0.5rem;
  margin: 0.125rem;
}
div[multiple] .opt-span .glyphicons-remove {
  padding: 0.35rem 0.25rem 0rem 0.35rem;
  font-size: 14px;
  cursor: pointer;
  color: #bd2c00;
}
div[multiple] .opt-span .glyphicons-remove:hover {
  color: red;
}
#timekeeper .btn.section-caret {
  margin-right: 1rem;
}
.line-spacing-1p3 {
  line-height: 1.3 !important;
}
dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.2);
}
.dialog-container {
  width: 100%;
  max-width: 35rem;
  color: #545454;
  border: none;
  border-radius: 5px;
  padding: 0;
}
.dialog-container .dialog-message {
  margin: 1em 1.6em 0.3em;
  padding: 0;
  overflow: auto;
  color: inherit;
  font-size: 1.125em;
  font-weight: normal;
  line-height: normal;
  word-wrap: break-word;
  word-break: break-word;
}
.dialog-container .dialog-title {
  padding: 0.8em 1em 0;
  font-size: 1.875em;
  font-weight: 600;
  word-wrap: break-word;
}
.dialog-container .button-container {
  padding: 0 0 1.25em;
}
.dialog-container #iconContainer {
  opacity: 0.5;
}
#time-out-dialog::backdrop {
  background-color: #387bbd;
}
#time-out-dialog {
  width: 35rem;
  max-width: 94%;
}
#time-out-dialog .button-container {
  padding: 1.7em 0 1.25em;
  font-size: 1em;
}
.dialog-open {
  height: auto;
  overflow: hidden;
}
